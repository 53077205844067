import React from 'react'
import { ThemeProvider } from '@leshen/ui'
import siteTheme from '../../theme'
import GlobalStyles from './CustomStyles'

const Wrapper = ({ children }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default Wrapper
